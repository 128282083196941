import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

export default ({ data }) => (
	<Layout>
		<SEO title="Compfight — Ryan Teuscher" />
		<nav>
			<Link to="/">
				<span className="material-icons">arrow_back</span>
			</Link>
		</nav>
		<header>
			<h1>Compfight. Superior photo hunting designed for speed.</h1>
			<h3>Acquired &mdash; Co-Founder & CEO</h3>
			<p>
				We started with a very simple mandate. Get our audience the best images
				on the web, in the fastest way possible. All design decisions were made
				with speed in mind, from the way images appear, to the number of images
				loaded on a given page.
			</p>
			<p>
				Our team created a powerful search engine distilling flickr into a
				massively searchable photo repository. Sporting deceptively simple user
				interaction methods, the site allows art directors, students, and
				educators to rapidly comb flickr for relevant content at a clip of
				200,000 searches per day. After 5 years, 6 million unique visitors, and
				over 1 billion pageviews, Compfight was acquired in 2013.
			</p>
		</header>
		<section>
			<figure>
				<Image alt="Home" filename="slide-compfight-search.png" />
			</figure>
			<article></article>
		</section>
	</Layout>
)
